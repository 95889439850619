<template>
  <div>
    <b-alert v-height-fade show dismissible fade class="mb-2" variant="primary">
      <div class="alert-body">
        <span>Klik tombol + untuk melakukan pengajuan cuti !</span>
      </div>
    </b-alert>
    <b-card title="Perubahan Cuti Karyawan">
      <b-row>
        <b-col md="12">
          <b-form class="p-2" @submit.prevent="SaveEditSickLeaves()">
            <b-form-group label="Tanggal Mulai Cuti">
              <flat-pickr v-model="periode_start" class="form-control" />
            </b-form-group>
            <b-form-group label="Tanggal Akhir Cuti">
              <flat-pickr v-model="periode_end" class="form-control" />
            </b-form-group>
            <b-form-group label="Alasan Cuti" label-for="alasan-cuti">
              <b-form-textarea
                id="alasan-cuti"
                v-model="employee_notes"
                rows="3"
                class="mb-2"
              />
            </b-form-group>
            <b-form-group>
              <b-card-text class="my-1">
                File : <strong>{{ file_name }}</strong>
              </b-card-text>
            </b-form-group>
            <b-form-group
              label="Lampiran"
              label-for="attachment_file"
            >
              <b-form-file
                id="attachment_file"
                v-model="attachment_file"
                accept="image/jpeg, application/pdf"
                @change="onChange"
              />

              <b-card-text class="my-1">
                Ukuran File : <strong>{{ attachment_file ? (attachment_file.size / 1048576).toFixed(2) + ' MB': ''  }} / 3 MB</strong>
              </b-card-text>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                block
              >
                <b-spinner
                  v-show="isSpinner"
                  class="ml-auto"
                  :hidden="!isSpinner"
                  small
                  type="grow"
                />
                <div :hidden="isSpinner">
                  <span class="align-middle">Simpan</span>
                </div>
              </b-button>
              <router-link
                  :to="{
                      name: 'sickLeaves',
                    }"
                >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
              >
                Batal
              </b-button>
              </router-link>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="v-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <b-card-text> {{ message }} </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BSpinner,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BFormTextarea,
  BFormFile
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
//import AddCutiSidebar from "./AddCutiSidebar.vue";

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardText,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BOverlay,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    BFormTextarea,
    BFormFile,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    // AddCutiSidebar,
    flatPickr,
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isSpinner: false,
      isDisableByVerified: true,
      message: null,
      employeeData: JSON.parse(localStorage.getItem("userData")),
      SickLeavesCode: this.$route.params._id,
      periode_start: [],
      periode_end: [],
      employee_notes: null,
      attachment_file: '',
      file_name: null
    };
  },
  mounted() {},
  created() {
    this.getSickLeaveData()
  },
  methods: {
    getSickLeaveData() {
      console.log(this.SickLeavesCode);
      if(this.SickLeavesCode !== null) {
        axios.post('sickLeaves/detil', {
        sick_leave_code: this.SickLeavesCode
        })
        .then(response => {
          console.log(response);
          this.periode_start = response.data.data.periode_start
          this.periode_end = response.data.data.periode_end
          this.employee_notes = response.data.data.employee_notes
          this.file_name = response.data.data.attachment_file_name
          this.detil = response.data
          console.log(this.attachment_file_name);
        })
      } else {
        console.log(error);
      }
    },
    onChange(event){
      this.attachment_file = event.target.files[0]
    },
    // SaveEditSickLeaves() {
    //   // console.log(this.voucher.voucher_id);
    //   this.isSpinner = true
    //   axios.post('/sickLeaves/update', {
    //     employee_code: this.employeeData.employee_code,
    //     sick_leave_code: this.SickLeavesCode,
    //     periode_start: this.periode_start,
    //     periode_end: this.periode_end,
    //     employee_notes: this.employee_notes,

    //   })
    //     .then(response => {
    //       this.$router.push({ name: "cuti" }).catch(() => {});
    //       console.log(response)
    //       this.$swal({
    //         icon: 'success',
    //         title: 'Data berhasil disimpan',
    //         timer: 1500,
    //         showCancelButton: false,
    //         showConfirmButton: false,
    //       }).then(
    //         () => {},
    //         // handling the promise rejection
    //         dismiss => {
    //           if (dismiss === 'timer') {
    //             // console.log('I was closed by the timer')
    //           }
    //         },
    //       )
    //     // }
    //     })
    //     .catch(error => {
    //       console.log(error.response.status);
    //       if (error.response.status === 400) {
    //         console.log(error.response.data.message)
    //         this.$refs['v-modal'].show()
    //         this.message = error.response.data.message
    //       } 
    //       if (error.response.status === 401) {
    //       this.$router.push({ name: "not-authorized" }).catch(() => {});
    //       } 
    //       else {
    //         console.log(error.response)
    //       }
    //     })
    //     .finally(() => {
    //       this.isSpinner = false
    //       // this.$refs['sidebar-right-update'].hide()
    //     })
    // },
    SaveEditSickLeaves() {
      const fdata = new FormData();
      fdata.append('_method', 'patch')
      fdata.append('sick_leave_code', this.SickLeavesCode)
      fdata.append('periode_start', this.periode_start)
      fdata.append('periode_end', this.periode_end)
      fdata.append('employee_notes', this.employee_notes)
      fdata.append('attachment_file', this.attachment_file)

      // let config = {
      //     header : {
      //     'Content-Type' : 'multipart/form-data'
      //   }
      // } 

      console.log(fdata);
      //this.isSpinner = true
      
      axios.post('/sickLeaves/update', fdata)
      // .then(res => { console.log(res);})
        .then(response => {
          if (response.data.data.length === 0) {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          this.$router.push({ name: 'sickLeaves' }).catch(() => {})
          } else {
            console.log(response)
            this.$refs['v-modal'].show()
            this.isValid = true
            this.message = response.data.message
            // console.log(this.message);
          }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['v-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false
          // this.resetForm()
          // this.$refs['sidebar-right'].hide()
          // this.$parent.fetchDataList()
        })
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data tidak ditemukan.",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data ditemukan.",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    // validationFormInfo() {
    //   this.$refs.infoRules.validate().then(success => {
    //     if (success) {
    //       this.getLeaveEmployee()
    //     } else {
    //       this.$refs['v-modal'].show()
    //     }
    //   })
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
